/*

This file defines the Home component, which includes a map interface with floating action buttons (FABs) for logout, 
layer management, and recentering the map, along with bathymetry and path legends that display relevant information based on the state of the application.

*/

import {
  IonContent,
  IonFab,
  IonPage,
} from "@ionic/react";
import { Map, SettingsSheet, CustomFabButton, LayerModal } from "../components";
import { useContext } from "react";
import { MapContext } from "../store/MapContext";
import { useDispatch } from "react-redux";
import { unauthenticateUser } from "../authorization/authSlice";
import { onLogout } from "../authorization/auth";
import { transform } from "ol/proj";
import { useHistory } from "react-router-dom";
import "./Home.css";

const FabButtons: React.FC = () => {
  const { setLayerModalIsOpen, map } = useContext(MapContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = async () => {
    try {
      await onLogout();

      dispatch(unauthenticateUser());
      localStorage.removeItem("isAuth");
      localStorage.removeItem("jwt_token");
      history.push("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleRecenter = () => {
    if (map) {
      const centerCoordinates = transform(
        [77.4838, 22.7541],
        "EPSG:4326",
        "EPSG:3857"
      );
      map.getView().setCenter(centerCoordinates);
      map.getView().setZoom(4);
    }
  };

  return (
    <>
      <IonFab slot="fixed" vertical="top" horizontal="start">
        <CustomFabButton
          imageSrc="assets/icon/logout.svg"
          className="home-logout-button"
          onPress={handleLogout} // Call the onLogout function when the button is pressed
        />
        <CustomFabButton
          imageSrc="assets/icon/layers.svg"
          className="home-layers-button"
          onPress={() => setLayerModalIsOpen(true)}
        />
      </IonFab>
      <IonFab slot="fixed" vertical="top" horizontal="end">
        <CustomFabButton
          id="open-settings-sheet"
          imageSrc="assets/icon/settings.svg"
          className="home-settings-button"
        />
        <CustomFabButton
          imageSrc="assets/icon/location.svg"
          className="home-recenter-button"
          onPress={handleRecenter}
        />
      </IonFab>
    </>
  );
};

const BathymetryLegend = () => {
  const legendItems = [
    { color: "rgb(240, 248, 255)", range: ">-500" }, // AliceBlue
    { color: "rgb(173, 216, 230)", range: ">-1000" }, // LightBlue
    { color: "rgb(135, 206, 250)", range: ">-1500" }, // SkyBlue
    { color: "rgb(100, 149, 237)", range: ">-2000" }, // CornflowerBlue
    { color: "rgb(70, 130, 180)", range: ">-2500" }, // SteelBlue
    { color: "rgb(65, 105, 225)", range: ">-3000" }, // RoyalBlue
    { color: "rgb(0, 0, 255)", range: ">-3500" }, // Blue
    { color: "rgb(0, 0, 205)", range: ">-4000" }, // MediumBlue
    { color: "rgb(0, 0, 139)", range: ">-4500" }, // DarkBlue
    { color: "rgb(0, 0, 128)", range: ">-5000" }, // Navy
    { color: "rgb(25, 25, 112)", range: "<= -5000" }, // MidnightBlue
  ];

  return (
    <div style={{ position: "absolute", bottom: 10, right: 10 }}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          padding: "10px",
          color: "black",
        }}
      >
        <h5 style={{ marginTop: "2px", fontSize: "15px", fontWeight: "bold" }}>
          Bathymetry Legend:{" "}
        </h5>

        {legendItems.map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          >
            <div
              style={{
                width: "20px",
                height: "10px",
                backgroundColor: item.color,
                marginRight: "5px",
                border: "1px solid black",
              }}
            ></div>
            <span style={{ fontSize: "12px" }}>({item.range}) elevation</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const PathLegend = () => {
  const { pathLengthAlpha, pathLengthNoAlpha } = useContext(MapContext);
  const legendItems = [
    {
      color: "rgb(0, 0, 255)",
      Alpha: "0",
      Length: pathLengthNoAlpha,
      Path: "Shortest",
    },
    {
      color: "rgb(255, 0, 0)",
      Alpha: "1",
      Length: pathLengthAlpha,
      Path: "Safest",
    },
  ];

  return (
    <div style={{ position: "absolute", bottom: 10, left: 10 }}>
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          padding: "10px",
          color: "black",
        }}
      >
        <h5 style={{ marginTop: "2px", fontSize: "15px", fontWeight: "bold" }}>
          Path Legend:{" "}
        </h5>

        {legendItems.map((item, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", marginTop: "5px" }}
          >
            <div
              style={{
                width: "20px",
                height: "10px",
                backgroundColor: item.color,
                marginRight: "5px",
              }}
            ></div>
            <span style={{ fontSize: "12px" }}>
              Path Length: {item.Length} km | Path Type: {item.Path}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Home: React.FC = () => {
  const { showBathymetry } = useContext(MapContext);
  const { showPathLegend } = useContext(MapContext);
  return (
    <>
      <LayerModal />
      <IonPage id="home-main-content">
        <IonContent fullscreen>
          <Map />
          <FabButtons />
          <SettingsSheet />
          {showBathymetry && <BathymetryLegend />}
          {showPathLegend && <PathLegend />}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
