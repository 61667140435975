/*

This file defines a Redux slice for user authentication, managing the authentication state (isAuth) based on local storage, 
and providing reducers to update this state for user authentication and unauthentication.

*/

import { createSlice } from "@reduxjs/toolkit";

const userAuthFromLocalStorage = () => {
  const isAuth = localStorage.getItem("isAuth");
  const token = localStorage.getItem("jwt_token");

  if (isAuth && JSON.parse(isAuth) === true) {
    return true;
  }

  return false;
};

const initialState = {
  isAuth: userAuthFromLocalStorage(),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateUser: (state: { isAuth: boolean }) => {
      state.isAuth = true;
    },
    unauthenticateUser: (state: { isAuth: boolean }) => {
      state.isAuth = false;
    },
  },
});

export const { authenticateUser, unauthenticateUser } = authSlice.actions;

export default authSlice.reducer;
