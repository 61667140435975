import { ChangeEvent, FormEvent, useContext, useState } from "react";
import { onLogin } from "../authorization/auth";
import { useDispatch } from "react-redux";
import { authenticateUser } from "../authorization/authSlice";
import isroImg from "./isro.png";
import { useHistory } from "react-router";
import "./Signup.css";
import { MapContext } from "../store/MapContext";

const Login = () => {
  const history = useHistory();
  const { email, setEmail } = useContext(MapContext);
  const onLoginClick = () => {
    history.push("/register");
  };

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Add loading state to display a loader or feedback during login

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    if (e.target.name === "email" && e.target.value.trim() !== "") {
      setEmail(e.target.value);
    }
    console.log("set Email is: ", email);
  };

  const dispatch = useDispatch();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Start loading when login process starts

    try {
      const response = await onLogin(values);
      console.log("Login successful:", response.data);
      dispatch(authenticateUser());
      localStorage.setItem("isAuth", "true");
      localStorage.setItem("jwt_token", response.data.access_token);

      // Redirect to home page after login
      setTimeout(() => {
        setEmail(values.email);
        history.push("/");
        window.location.reload();
      }, 100);
    } catch (error: any) {
      console.error("Login error:", error);

      // Safely handle the error message
      const errorMessage =
        error.response?.data?.errors?.[0]?.msg ||
        "Login Failed. Kindly check credentials";
      setError(errorMessage);

      // Stop the loader
      setLoading(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <img src={isroImg} alt="Signup" className="signup-image" />
        <p>SAFE SHIP NAVIGATION</p>
        <form id="myForm" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              onChange={(e) => onChange(e)}
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={values.email}
              placeholder="Email"
              required
            />
          </div>

          <div className="form-group">
            <input
              onChange={(e) => onChange(e)}
              type="password"
              value={values.password}
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
              required
            />
          </div>

          {loading && (
            <div className="loader-container">
              Logging you in, please wait...
            </div>
          )}

          {/* Display error message if login fails */}
          <div style={{ color: "red", margin: "10px 0" }}>{error}</div>

          <button type="submit" className="submit-button">
            Login
          </button>
          <button type="button" className="login-button" onClick={onLoginClick}>
            Sign-up
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
