/*

This file defines a Register component, handling user registration with input validation for email and password, 
managing loading and error states, and providing navigation to a login page.

*/

import React, { useState, FormEvent, ChangeEvent } from "react";
import "./Signup.css";
import isroImg from "./isro.png";
import { onRegistration } from "../authorization/auth";
import { useHistory } from "react-router";

interface Values {
  email: string;
  username: string;
  password: string;
}

const Register: React.FC = () => {
  const history = useHistory();
  const onLoginClick = () => {
    history.push("/login");
  };
  const [values, setValues] = useState<Values>({
    email: "",
    username: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  const isEmailValid = (email: string): boolean => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  };

  const isPasswordValid = (password: string): boolean => {
    const passwordPattern = /^(?=.*[A-Z])(?=.*[\W_]).{8,12}$/;
    return passwordPattern.test(password);
  };
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isEmailValid(values.email)) {
      setError("Invalid email address");
      setSuccess(null);
      return;
    }

    if (!isPasswordValid(values.password)) {
      setError(
        "Password must be 8-12 characters long, contain at least one uppercase letter, and one special character."
      );
      setSuccess(null);
      return;
    }

    try {
      setLoading(true);
      const { data } = await onRegistration(values);

      setError(null);
      setSuccess(data.message);
      setValues({ email: "", username: "", password: "" });
      // history.push("/");
    } catch (error: any) {
      setError(error.response.data.errors[0].msg);
      setSuccess(null);
    } finally {
      setLoading(true); // Set loading back to false regardless of success or failure
    }
  };
  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;

    if (!isEmailValid(email)) {
      setError("Invalid email address");
    } else {
      setError(null);
    }

    setValues({ ...values, email });
  };

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;

    if (!isPasswordValid(password)) {
      setError(
        "Password must be 8-12 characters long, contain at least one uppercase letter, and one special character."
      );
    } else {
      setError(null);
    }

    setValues({ ...values, password });
  };

  return (
    <div className="signup-container">
      <div className="signup-box">
        <img src={isroImg} alt="Signup" className="signup-image" />
        <p>SAFE SHIP NAVIGATION</p>
        <form id="myForm" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              onChange={(e) => onChange(e)}
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={values.username}
              placeholder="Username"
              required
            />
          </div>

          <div className="form-group">
            <input
              onChange={onEmailChange}
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={values.email}
              placeholder="Email"
              required
            />
          </div>

          <div className="form-group">
            <input
              onChange={onPasswordChange}
              type="password"
              value={values.password}
              className="form-control"
              id="password"
              name="password"
              placeholder="Password"
              required
            />
          </div>
          {loading && (
            <div className="loader-container">
              We have sent you an email please verify yourself!
            </div>
          )}

          <div style={{ color: "red", margin: "10px 0" }}>{error}</div>
          <div style={{ color: "green", margin: "10px 0" }}>{success}</div>
          <button type="submit" className="submit-button">
            Sign-up
          </button>
          <button type="button" className="login-button" onClick={onLoginClick}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
