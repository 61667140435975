/*

This file defines functions to generate B-spline curve points and create a corresponding line layer on a map using OpenLayers, 
while also making an API call to retrieve path data based on specified source and destination coordinates.

*/

import bspline from "b-spline";
import { fromLonLat } from "ol/proj";
import axiosInstance from "../services/axiosInstance";
import { Feature } from "ol";
import { LineString } from "ol/geom";
import VectorSource from "ol/source/Vector";
import { Style, Stroke } from "ol/style";
import VectorLayer from "ol/layer/Vector";

const baseIP = process.env.REACT_APP_BASE_IP || "http://localhost:5001/";

// Function to generate points along a B-spline curve
export const bSplinePointsGenerator = (
  points: number[][],
  degree: number,
  destination: number[],
  source: number[]
) => {
  var pointsOnTheCurve: number[][] = [fromLonLat([source[1], source[0]])];

  for (let t = 0; t < 1; t += 0.01) {
    var point = bspline(t, degree, points);
    pointsOnTheCurve.push(fromLonLat(point));
  }

  pointsOnTheCurve.push(fromLonLat([destination[1], destination[0]]));

  return pointsOnTheCurve;
};

// Function to generate a B-spline line layer on the map
export const bSplineLineLayerGenerator = async (
  source: string,
  destination: string,
  degree: number,
  neighbors: number,
  vessel_type: string,
  alpha: number,
  map: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  pathLengthAlpha: number,
  setPathLengthAlpha: React.Dispatch<React.SetStateAction<number>>,
  pathLengthNoAlpha: number,
  setPathLengthNoAlpha: React.Dispatch<React.SetStateAction<number>>
) => {
  setLoading(true);

  const SRC_COORDINATES = source.split(", ");
  const DEST_COORDINATES = destination.split(", ");

  console.log(parseInt(SRC_COORDINATES[0]), parseInt(SRC_COORDINATES[1]));
  console.log(parseInt(DEST_COORDINATES[0]), parseInt(DEST_COORDINATES[1]));

  try {
    // Make a POST request to the backend API to get the path based on input parameters
    const res = await axiosInstance.post(`${baseIP}stream-path`, {
      source_coords: [
        parseInt(SRC_COORDINATES[0]),
        parseInt(SRC_COORDINATES[1]),
      ],
      destination_coords: [
        parseInt(DEST_COORDINATES[0]),
        parseInt(DEST_COORDINATES[1]),
      ],
      degree: degree,
      neighbors: neighbors,
      vessel_type: vessel_type,
      alpha: alpha,
    });

    // Extract points and path length from the response
    const points = res.data.path;
    const pathLength = res.data.distance.toFixed(2);

    console.log("PATH LENGTH: ", pathLength);

    // Update the appropriate state for path length based on the alpha value
    if (alpha == 1) {
      setPathLengthAlpha(pathLength);
    } else {
      setPathLengthNoAlpha(pathLength);
    }

    // Check if points were returned in the response; if not, alert the user
    if (!points) {
      window.alert(
        "Either the coordinates lie on land OR Start point is unsafe currently"
      );
      setLoading(false);
      return;
    }

    const destinationArrSTR = destination.split(",");
    const sourceArrSTR = source.split(",");
    console.log("POINTS: ", points);

    const destinationArr = [
      parseFloat(destinationArrSTR[0]),
      parseFloat(destinationArrSTR[1]),
    ];
    const sourceArr = [
      parseFloat(sourceArrSTR[0]),
      parseFloat(sourceArrSTR[1]),
    ];

    const newPoints = points.map((point: number[]) => {
      return [point[1], point[0]];
    });

    // Generate points on the B-spline curve using the previously defined function
    const bsplinePointsOnCurve = bSplinePointsGenerator(
      newPoints,
      8,
      destinationArr,
      sourceArr
    );

    console.log("BSPLINE POINTS ON CURVE: ", bsplinePointsOnCurve);

    const bSplineLineString = new Feature({
      geometry: new LineString(bsplinePointsOnCurve),
      name: "BSpline LineString",
    });

    const bSplineVectorSource = new VectorSource({
      features: [bSplineLineString],
      wrapX: false,
    });

    /* https://openlayers.org/en/latest/apidoc/module-ol_style_Stroke.html */
    const lineStyle = new Style({
      stroke: new Stroke({
        color: alpha == 1 ? "red" : "blue", // Path color based on alpha value
        width: 5,
        lineCap: "butt",
      }),
    });

    const bSplinePathLayer = new VectorLayer({
      source: bSplineVectorSource,
      style: lineStyle,
    });

    bSplinePathLayer.set("name", "bSplinPathLayer");

    map.addLayer(bSplinePathLayer);
  } catch (error) {
    // Handle any errors that occur during the process and alert the user
    window.alert(
      `Error generating bSpline line layer: ${
        error instanceof Error ? error.message : error
      }`
    );
    console.error("Error generating bSpline line layer:", error);
  } finally {
    setLoading(false);
  }
};
