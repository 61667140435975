/*

This file creates and exports a configured Axios instance with a base URL for making HTTP requests, 
allowing for easier API communication throughout the application.

*/

import axios from "axios";

const baseIP = process.env.REACT_APP_BASE_IP || "http://localhost:5001/";

const axiosInstance = axios.create({
    baseURL: baseIP, 
});

export default axiosInstance;
