import { Style, Fill } from "ol/style";
import { FeatureLike } from "ol/Feature";

interface IProps {
  feature: FeatureLike;
}

const generateBathymetryStyles = ({ feature }: IProps) => {
  var elevation = feature.get("elevation");
  // generate blue color shades according to the given sea elevation
  var color;
  if (elevation >= 0) {
    color = `rgba(0,0,0,0)`;
  } else if (elevation > -500) {
    color = `rgb(240, 248, 255)`; // AliceBlue
  } else if (elevation > -1000) {
    color = `rgb(173, 216, 230)`; // LightBlue
  } else if (elevation > -1500) {
    color = `rgb(135, 206, 250)`; // SkyBlue
  } else if (elevation > -2000) {
    color = `rgb(100, 149, 237)`; // CornflowerBlue
  } else if (elevation > -2500) {
    color = `rgb(70, 130, 180)`; // SteelBlue
  } else if (elevation > -3000) {
    color = `rgb(65, 105, 225)`; // RoyalBlue
  } else if (elevation > -3500) {
    color = `rgb(0, 0, 255)`; // Blue
  } else if (elevation > -4000) {
    color = `rgb(0, 0, 205)`; // MediumBlue
  } else if (elevation > -4500) {
    color = `rgb(0, 0, 139)`; // DarkBlue
  } else if (elevation > -5000) {
    color = `rgb(0, 0, 128)`; // Navy
  } else {
    color = `rgb(25, 25, 112)`; // MidnightBlue
  }

  // const color = `rgba(0, 0, ${Math.round((elevation / 10000) * 255)}, 0.9)`;
  const bathymetryStyles = new Style({
    fill: new Fill({
      color: color,
    }),
  });

  return bathymetryStyles;
};

export default generateBathymetryStyles;
