/*

This file defines two route components, PrivateRoute and RestrictedRoute, which manage access control based on the validity of a JWT token stored in localStorage.

- PrivateRoute renders a specified component if the JWT token is valid; otherwise, it redirects to the login page.
- RestrictedRoute renders a specified component if the JWT token is invalid; otherwise, it redirects to the home page.

The isTokenValid function checks the token's validity by decoding it and verifying its expiration.

*/

import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const jwtToken = localStorage.getItem('jwt_token') || ''; // Retrieve token from localStorage or wherever it's stored

  return (
    <Route
      {...rest}
      render={(props) =>
        isTokenValid(jwtToken) ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

interface RestrictedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const jwtToken = localStorage.getItem('jwt_token') || ''; // Retrieve token from localStorage or wherever it's stored

  return (
    <Route
      {...rest}
      render={(props) =>
        !isTokenValid(jwtToken) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const isTokenValid = (token: string): boolean => {
  if (!token) {
    return false;
  }

  try {
    const decodedToken = token.split('.')[1];
    const decodedPayload = atob(decodedToken);
    const payloadData = JSON.parse(decodedPayload);

    // Check if token is expired based on current time
    const expirationTime = payloadData.exp * 1000;
    const currentTime = Date.now();
    const remainingTime = expirationTime - currentTime;

    if (remainingTime <= 0) {
      console.log("Token has expired.");
      return false; // Token is expired
    }

    // Log remaining time in seconds
    console.log(`Session will expire in ${Math.floor(remainingTime / 1000)} seconds`);

    return true;
  } catch (error) {
    console.error('Token validation error:', error);
    return false; // Handle any decoding errors here
  }
};

export { PrivateRoute, RestrictedRoute };
