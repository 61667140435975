/*

This file sets up a React context and provider for managing the state and actions of a map application using OpenLayers, 
enabling features like path generation, map interactions, and displaying geographical data.

*/

import React, { createContext, useEffect, useRef, useState } from "react";

import GeoJSON from "ol/format/GeoJSON";
import { Coordinate, toStringXY } from "ol/coordinate";
import Overlay from "ol/Overlay";
import * as ol from "ol";
import { Geometry, Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { transform, fromLonLat } from "ol/proj";

import mockdata from "../assets/mock-geojson.json";

import { bSplineLineLayerGenerator } from "../helper/bSplinePlotter";
import windLayerGenerator from "../layers/wind-layer/wind_layer_generator";
import bathymetryLayerGenerator from "../layers/bathymetry/bathymetry_layer_generator";
import { removeLayer } from "../constants/removeLayer";

const baseIP = process.env.REACT_APP_BASE_IP || "http://localhost:5001/";

// Define the structure of the context type for the map
interface IMapContextType {
  map: ol.Map | undefined;
  setMap: React.Dispatch<React.SetStateAction<ol.Map | undefined>>;

  featuresLayer: VectorLayer<VectorSource<Geometry>> | undefined;
  setFeaturesLayer: React.Dispatch<
    React.SetStateAction<VectorLayer<VectorSource<Geometry>> | undefined>
  >;

  begin: boolean;
  setBegin: React.Dispatch<React.SetStateAction<boolean>>;

  mapElement: React.MutableRefObject<HTMLElement | undefined>;

  popup: Overlay;

  layerLoading: boolean;
  setLayerLoading: React.Dispatch<React.SetStateAction<boolean>>;

  selectedCoord: Coordinate | undefined;
  setSelectedCoord: React.Dispatch<
    React.SetStateAction<Coordinate | undefined>
  >;

  source: string | undefined;
  setSource: React.Dispatch<React.SetStateAction<string | undefined>>;

  destination: string | undefined;
  setDestination: React.Dispatch<React.SetStateAction<string | undefined>>;

  showWind: boolean;
  setShowWind: React.Dispatch<React.SetStateAction<boolean>>;

  showBathymetry: boolean;
  setShowBathymetry: React.Dispatch<React.SetStateAction<boolean>>;

  showPathLegend: boolean;
  setShowPathLegend: React.Dispatch<React.SetStateAction<boolean>>;

  isSearched: boolean;
  setIsSearched: React.Dispatch<React.SetStateAction<boolean>>;

  features: ol.Feature<Geometry>[];
  setFeatures: React.Dispatch<React.SetStateAction<ol.Feature<Geometry>[]>>;

  isLoadingMap: boolean;
  setIsLoadingMap: React.Dispatch<React.SetStateAction<boolean>>;

  layerModalIsOpen: boolean;
  setLayerModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

  pathLengthAlpha: number;
  setPathLengthAlpha: React.Dispatch<React.SetStateAction<number>>;

  pathLengthNoAlpha: number;
  setPathLengthNoAlpha: React.Dispatch<React.SetStateAction<number>>;

  resolutionDegree: number;
  setResolutionDegree: React.Dispatch<React.SetStateAction<number>>;

  vesselType: string;
  setVesselType: React.Dispatch<React.SetStateAction<string>>;

  neighbors: number;
  setNeighbors: React.Dispatch<React.SetStateAction<number>>;

  email: string | undefined;
  setEmail: React.Dispatch<React.SetStateAction<string | undefined>>;

  timestamp: string;
  setTimestamp: React.Dispatch<React.SetStateAction<string>>;

  clickedCoordinates: Coordinate[];

  handleMapClick: (event: any) => void;
  handleSearch: () => Promise<void>;
  handlePathGenerator: () => Promise<void>;
  handleRemoveCurrentRoute: () => void;
}

// Create a context for the map, defining its state and functions for managing map-related data and actions
export const MapContext = createContext<IMapContextType>({
  map: undefined,
  begin: false,
  email: "demo",
  popup: new Overlay({
    element: document.getElementById("popup") as HTMLElement,
  }),
  source: undefined,
  features: [],
  showWind: false,
  neighbors: 4,
  timestamp: "",
  isSearched: false,
  vesselType: "medium",
  mapElement: { current: undefined },
  destination: undefined,
  layerLoading: false,
  isLoadingMap: false,
  selectedCoord: undefined,
  featuresLayer: undefined,
  showBathymetry: false,
  showPathLegend: false,
  pathLengthAlpha: 0,
  pathLengthNoAlpha: 0,
  layerModalIsOpen: false,
  resolutionDegree: 1,
  clickedCoordinates: [],

  setMap: () => {},
  setBegin: () => {},
  setEmail: () => {},
  setSource: () => {},
  setFeatures: () => {},
  setShowWind: () => {},
  setNeighbors: () => {},
  setTimestamp: () => {},
  setIsSearched: () => {},
  setVesselType: () => {},
  setDestination: () => {},
  setIsLoadingMap: () => {},
  setLayerLoading: () => {},
  setFeaturesLayer: () => {},
  setSelectedCoord: () => {},
  setShowBathymetry: () => {},
  setShowPathLegend: () => {},
  setPathLengthAlpha: () => {},
  setLayerModalIsOpen: () => {},
  setResolutionDegree: () => {},
  setPathLengthNoAlpha: () => {},

  handleSearch: () => Promise.resolve(),
  handleMapClick: () => {},
  handlePathGenerator: () => Promise.resolve(),
  handleRemoveCurrentRoute: () => {},
});

// Define the props for the MapProvider component, which includes children elements
interface IMapProviderProps {
  children: React.ReactNode;
}

// MapProvider component that provides context and state for the map application
const MapProvider = ({ children }: IMapProviderProps) => {
  // State variables to manage map-related data
  const [map, setMap] = useState<ol.Map>();
  const [begin, setBegin] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [source, setSource] = useState<any>();
  const [features, setFeatures] = useState<ol.Feature<Geometry>[]>([]);
  const [showWind, setShowWind] = useState<boolean>(false);
  const [neighbors, setNeighbors] = useState<number>(4);
  const [timestamp, setTimestamp] = useState<string>("");
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [vesselType, setVesselType] = useState<string>("medium");
  const [destination, setDestination] = useState<any>();
  const [isLoadingMap, setIsLoadingMap] = useState<boolean>(true);
  const [layerLoading, setLayerLoading] = useState<boolean>(false);
  const [featuresLayer, setFeaturesLayer] =
    useState<VectorLayer<VectorSource<Geometry>>>();
  const [selectedCoord, setSelectedCoord] = useState<Coordinate>();
  const [showBathymetry, setShowBathymetry] = useState<boolean>(false);
  const [showPathLegend, setShowPathLegend] = useState<boolean>(false);
  const [pathLengthAlpha, setPathLengthAlpha] = useState<number>(0);
  const [layerModalIsOpen, setLayerModalIsOpen] = useState<boolean>(false);
  const [resolutionDegree, setResolutionDegree] = useState<number>(1);
  const [pathLengthNoAlpha, setPathLengthNoAlpha] = useState<number>(0);
  const [clickedCoordinates, setClickedCoordinates] = useState<Coordinate[]>(
    []
  );

  // Refs for map element and map instance
  const mapElement = useRef<HTMLDivElement>();
  const mapRef = useRef<ol.Map>();
  const cont = document.createElement("div");

  mapRef.current = map;
  cont.className = "popup";

  // Create a new overlay for the popup
  const popup = new Overlay({
    element: cont,
    autoPan: {
      animation: {
        duration: 250,
      },
    },
  });

  const handleSearch = async () => {
    const windLayer = await windLayerGenerator({
      url: `${baseIP}get-circle`,
      method: "POST",
      data: { input1: source, input2: destination },
      setLoading: setLayerLoading,
    });
    if (map) {
      map.addLayer(windLayer);
    }
  };

  // Function to handle map click events
  const handleMapClick = (event: any) => {
    const coord = mapRef.current?.getCoordinateFromPixel(event.pixel);
    const feature = mapRef.current?.forEachFeatureAtPixel(
      event.pixel,
      function (feature) {
        return feature;
      }
    );

    // If a feature is clicked, show its details in the popup
    if (feature && coord) {
      const transformedCoord = transform(coord, "EPSG:3857", "EPSG:4326");
      console.log("Clicked on: ", transformedCoord);

      // Check if feature has wind data
      if (feature.get("uwnd") && feature.get("vwnd")) {
        const event = new Date(feature.get("timestamp"));
        const time = event.toLocaleString();

        // Set the popup content with wind data
        cont.innerHTML = `<p class="popup-text">Location: ${getYX(
          transformedCoord
        )}</p>
        <p class="popup-text">Timestamp: ${time} </p>
        <p class="popup-text">Wind Speed: ${(
          Math.sqrt(
            feature.get("uwnd") * feature.get("uwnd") +
              feature.get("vwnd") * feature.get("vwnd")
          ) / 10
        ).toFixed(4)} m/s</p>
        <p class="popup-text">Wind Direction: ${feature
          .get("dir")
          .toFixed(4)} radians</p>`;
      } else {
        // If no wind data, show other feature data
        cont.innerHTML = `<p class="popup-text">Location: ${getYX(
          transformedCoord
        )}</p><p class="popup-text">Height from sea level: ${feature.get(
          "Elevation"
        )} m</p>`;
      }
      popup.setPosition(event.coordinate);
    } else {
      popup.setPosition(undefined);
    }

    // If a coordinate is clicked, save it
    if (coord) {
      const transformedCoord = transform(coord, "EPSG:3857", "EPSG:4326");
      setSelectedCoord(transformedCoord);
      console.log(transformedCoord);
      setClickedCoordinates((prevCoordinates) => [
        ...prevCoordinates,
        transformedCoord,
      ]);
    }
  };

  // Function to handle path generation between source and destination
  const handlePathGenerator = async () => {
    // Ensure both source and destination are selected
    if (!source || !destination) {
      alert("Please select both source and destination points.");
      return;
    }
    // Ensure source and destination are different
    if (source == destination) {
      alert("Please select different coordinates for source and destination.");
      return;
    }

    const SRC_COORDINATES = source.split(", ");
    const DEST_COORDINATES = destination.split(", ");

    // Check if source and destination are too close
    if (
      Math.abs(parseInt(SRC_COORDINATES[0]) - parseInt(DEST_COORDINATES[0])) +
        Math.abs(
          parseInt(SRC_COORDINATES[1]) - parseInt(DEST_COORDINATES[1])
        ) <=
      1.0
    ) {
      alert("Source and Destination are too close!");
      return;
    }

    console.log(source, destination, resolutionDegree, neighbors, vesselType); // Log parameters for debugging

    // Remove the current route before generating a new one
    handleRemoveCurrentRoute();

    // Shortest Path (Alpha = 0)
    await bSplineLineLayerGenerator(
      source,
      destination,
      resolutionDegree,
      neighbors,
      vesselType,
      0,
      map,
      setLayerLoading,
      pathLengthAlpha,
      setPathLengthAlpha,
      pathLengthNoAlpha,
      setPathLengthNoAlpha
    );

    // Safest Path (Alpha = 1)
    await bSplineLineLayerGenerator(
      source,
      destination,
      resolutionDegree,
      neighbors,
      vesselType,
      1,
      map,
      setLayerLoading,
      pathLengthAlpha,
      setPathLengthAlpha,
      pathLengthNoAlpha,
      setPathLengthNoAlpha
    );

    // Show the path legend
    setShowPathLegend(true);
  };

  // Function to remove the current route from the map
  const handleRemoveCurrentRoute = () => {
    if (map) {
      removeLayer(map, "bSplinPathLayer");
      setShowPathLegend(false);
    }
  };

  // Function to convert coordinate to a human-readable format
  const getYX = (coord: Coordinate) => {
    const arr = toStringXY(coord, 4).split(", ");
    const lat = parseFloat(arr[1]);
    const lon = parseFloat(arr[0]);
    return `${Math.abs(lat)} ${lat < 0 ? "S" : "N"}, ${Math.abs(lon)} ${
      lon < 0 ? "W" : "E"
    }`;
  };

  // Effect to load mock data and initialize features on component mount
  useEffect(() => {
    const response = JSON.stringify(mockdata);
    const wktOptions = {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:3857",
    };
    const parsedFeatures = new GeoJSON().readFeatures(response, wktOptions);
    setFeatures(parsedFeatures);
    setIsLoadingMap(false);
  }, []);

  // Effect to manage clicked coordinates
  useEffect(() => {
    // Debug statements
    console.log("Clicked Coordinates: ", clickedCoordinates);
    console.log(
      "Latest Clicked Coordinate: ",
      clickedCoordinates[clickedCoordinates.length - 1]
    );
    console.log(
      "Previous Clicked Coordinate: ",
      clickedCoordinates[clickedCoordinates.length - 2]
    );

    // Check if there is a previous clicked coordinate
    if (clickedCoordinates[clickedCoordinates.length - 2] != null) {
      const source = clickedCoordinates[clickedCoordinates.length - 2];
      const pointFeature = new ol.Feature({
        geometry: new Point(fromLonLat([source[0], source[1]])),
        name: "Point",
      });
      const pointVectorSource = new VectorSource({
        features: [pointFeature],
        wrapX: false,
      });
      const pointVectorLayer = new VectorLayer({
        source: pointVectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: "rgba(0, 255, 0, 0.7)",
            }),
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.8)",
              width: 2,
            }),
          }),
        }),
      });

      pointVectorLayer.set("name", "pointLayerSource");

      if (map) {
        removeLayer(map, "pointLayerSource");
        map.addLayer(pointVectorLayer);
      }
    }

    // Check if there is a current clicked coordinate
    if (clickedCoordinates[clickedCoordinates.length - 1] != null) {
      const dest = clickedCoordinates[clickedCoordinates.length - 1];
      const pointFeature = new ol.Feature({
        geometry: new Point(fromLonLat([dest[0], dest[1]])),
        name: "Point",
      });
      const pointVectorSource = new VectorSource({
        features: [pointFeature],
        wrapX: false,
      });
      const pointVectorLayer = new VectorLayer({
        source: pointVectorSource,
        style: new Style({
          image: new CircleStyle({
            radius: 8,
            fill: new Fill({
              color: "rgba(255, 0, 0, 0.7)",
            }),
            stroke: new Stroke({
              color: "rgba(0, 0, 0, 0.8)",
              width: 2,
            }),
          }),
        }),
      });
      pointVectorLayer.set("name", "pointLayerDestination");

      if (map) {
        removeLayer(map, "pointLayerDestination");
        map.addLayer(pointVectorLayer);
      }
    }
  }, [clickedCoordinates]);

  // Effect to initialize the map when the component mounts
  useEffect(() => {
    if (begin) {
      setTimeout(() => {
        const centerCoordinates = transform(
          [77.4838, 22.7541],
          "EPSG:4326",
          "EPSG:3857"
        );

        const initialMap = new ol.Map({
          target: mapElement.current,
          layers: [
            new TileLayer({
              source: new OSM({
                url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}", // Use OSM Humanitarian map style
              }),
            }),
          ],
          view: new ol.View({
            center: centerCoordinates,
            zoom: 5,
            projection: "EPSG:3857",
          }),
          controls: [],
        });
        initialMap.on("click", handleMapClick);
        initialMap.addOverlay(popup);
        setMap(initialMap);
      });
    }
    setBegin(true);
  }, [begin]);

  // Effect to fetch and manage wind data layer
  useEffect(() => {
    const getWindData = async () => {
      const encodedTimestamp = encodeURIComponent(timestamp);
      console.log("Selected timestamp: ", timestamp);
      if (showWind && map) {
        const windLayer = await windLayerGenerator({
          url: `${baseIP}get-weather-data${
            timestamp ? `?timestamp=${encodedTimestamp}` : ""
          }`, // Include timestamp if set
          method: "GET",
          data: {},
          setLoading: setLayerLoading,
        });

        windLayer.set("name", "windLayer");
        map.addLayer(windLayer);
      } else if (!showWind && map) {
        removeLayer(map, "windLayer");
      }
    };
    getWindData();
  }, [map, showWind, timestamp]); // Add selectedTimestamp to dependency array

  // Effect to fetch and manage bathymetry data layer
  useEffect(() => {
    const getBathmetryData = async () => {
      if (showBathymetry && map) {
        const bathymetryLayer = await bathymetryLayerGenerator({
          url: `${baseIP}get-bathymetry-polygons`,
          method: "GET",
          data: {},
          setLoading: setLayerLoading,
        });

        bathymetryLayer.set("name", "bathymetryLayer");
        map.addLayer(bathymetryLayer);
      } else if (!showBathymetry && map) {
        removeLayer(map, "bathymetryLayer");
      }
    };
    getBathmetryData();
  }, [map, showBathymetry]);

  // Effect to update the features layer when features data changes
  useEffect(() => {
    if (features && features.length) {
      featuresLayer?.setSource(
        new VectorSource({
          features: features,
        })
      );
      var extent = featuresLayer?.getSource()?.getExtent();
      if (extent !== undefined) {
        map?.getView().fit(extent, {
          padding: [50, 50, 50, 50],
        });
      }
    }
  }, [features, featuresLayer, map]);

  // Provide context values for child components
  return (
    <MapContext.Provider
      value={{
        map,
        email,
        popup,
        begin,
        source,
        showWind,
        features,
        neighbors,
        timestamp,
        mapElement,
        vesselType,
        isSearched,
        destination,
        layerLoading,
        isLoadingMap,
        selectedCoord,
        featuresLayer,
        showBathymetry,
        showPathLegend,
        pathLengthAlpha,
        resolutionDegree,
        layerModalIsOpen,
        pathLengthNoAlpha,
        clickedCoordinates,
        setMap,
        setBegin,
        setEmail,
        setSource,
        setFeatures,
        setShowWind,
        setNeighbors,
        setTimestamp,
        setIsSearched,
        setVesselType,
        setDestination,
        setLayerLoading,
        setIsLoadingMap,
        setFeaturesLayer,
        setSelectedCoord,
        setShowBathymetry,
        setShowPathLegend,
        setPathLengthAlpha,
        setLayerModalIsOpen,
        setResolutionDegree,
        setPathLengthNoAlpha,
        handleSearch,
        handleMapClick,
        handlePathGenerator,
        handleRemoveCurrentRoute,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
