/*

This file defines and exports several asynchronous functions for user registration, login, logout, and fetching protected information, 
using Axios to make HTTP requests to a specified backend API with support for credentials.

*/

import axios from 'axios'
axios.defaults.withCredentials = true
const baseIP = process.env.REACT_APP_BASE_IP || "http://localhost:5001/";

export async function onRegistration(registrationData: any) {
  return await axios.post(
    // 'http://localhost:8000/api/register',
    `${baseIP}register`,
    registrationData
  )
}

export async function onLogin(loginData: any) {
  return await axios.post(`${baseIP}login`, loginData)
}

export async function onLogout() {
  const token = localStorage.getItem('jwt_token');
  console.log(token)

  // If token is available, include it in the headers
  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  try {
    return await axios.post(`${baseIP}logout`, { headers });
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
}

export async function fetchProtectedInfo() {
  return await axios.get(`${baseIP}protected`)
}
