/*

This file defines a React component (SettingsSheet) that presents a modal for setting coordinates, vessel type, neighbors,
and resolution degree, allowing users to generate and manage routes on a map within an Ionic application.

*/

import {
  IonContent,
  IonModal,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { Coordinate, toStringXY } from "ol/coordinate";
import { useContext, useRef, useEffect, useState } from "react";
import { MapContext } from "../../store/MapContext";
import "./SettingsSheet.css";

const SettingsSheet = () => {
  const modal = useRef<HTMLIonModalElement>(null);

  // Function to format coordinates from the OpenLayers coordinate format to a string representation
  const getYX = (coord: Coordinate) => {
    const arr = toStringXY(coord, 4).split(", ");
    const lat = parseFloat(arr[1]);
    const lon = parseFloat(arr[0]);
    return `${Math.abs(lat)} ${lat < 0 ? "S" : "N"}, ${Math.abs(lon)} ${
      lon < 0 ? "W" : "E"
    }`;
  };

  // Function to reverse the coordinate string format
  const reverseCoordinates = (coordinate: Coordinate) => {
    if (coordinate) {
      const coordString = toStringXY(coordinate, 4);
      const [a, b] = coordString.split(", ");
      return `${b}, ${a}`;
    }
    return "";
  };

  // Destructuring values from the MapContext
  const {
    selectedCoord,
    handlePathGenerator,
    layerLoading,
    setDestination,
    setSource,
    handleRemoveCurrentRoute,
    clickedCoordinates,
    setVesselType,
    setResolutionDegree,
    setNeighbors,
    neighbors,
    vesselType,
    resolutionDegree,
  } = useContext(MapContext); // Accessing state and functions from the context

  // Extracting last two clicked coordinates from the array
  const lastClickedCoordinate =
    clickedCoordinates[clickedCoordinates.length - 1];
  const secondLastClickedCoordinate =
    clickedCoordinates[clickedCoordinates.length - 2];

  const lastClickedCoordinateReversed = reverseCoordinates(
    lastClickedCoordinate
  );
  const secondLastClickedCoordinateReversed = reverseCoordinates(
    secondLastClickedCoordinate
  );

  // Effect hook to update source and destination coordinates when clicked coordinates change
  useEffect(() => {
    if (secondLastClickedCoordinateReversed) {
      console.log("second last clicked: " + secondLastClickedCoordinate);
      setSource(secondLastClickedCoordinateReversed);
    }
    if (lastClickedCoordinateReversed) {
      console.log("last clicked: " + lastClickedCoordinate);
      setDestination(lastClickedCoordinateReversed);
    }
  }, [lastClickedCoordinateReversed, secondLastClickedCoordinateReversed]);

  
  // Styles for various elements in the modal
  const vesselTypeItemStyle = {
    marginTop: "60px",
  };

  const selectStyle1 = {
    backgroundColor: "#808080",
    color: "#fff",
    padding: "8px",
    borderRadius: "4px",
    marginLeft: "90px",
  };
  const selectStyle2 = {
    backgroundColor: "#808080",
    color: "#fff",
    padding: "8px",
    borderRadius: "4px",
    marginLeft: "95px",
  };
  const selectStyle3 = {
    backgroundColor: "#808080",
    color: "#fff",
    padding: "8px",
    borderRadius: "4px",
    marginLeft: "25px",
  };

  const closeButtonStyle = {
    backgroundColor: "transparent",
    border: "none",
    fontSize: "24px",
    cursor: "pointer",
    color: "#fff",
    transition: "color 0.3s",
    font: "bold",
  };

  const closeButtonHoverStyle = {
    color: "#aaa",
  };


  // Handle mouse events for close button
  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.color = closeButtonHoverStyle.color;
  };
  const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.style.color = closeButtonStyle.color;
  };

  return (
    <IonModal
      ref={modal}
      trigger="open-settings-sheet"
      initialBreakpoint={0.75}
      breakpoints={[0, 0.25, 0.5, 0.75, 1]}
    >
      <IonContent className="ion-padding">
        <div className="bottom-container">
          {selectedCoord && (
            <>
              <div className="cord-container">
                <span className="inline-title">Coordinate</span>
                <span className="text">
                  {selectedCoord ? getYX(lastClickedCoordinate) : ""}
                </span>
              </div>
              <div className="divider" />
            </>
          )}
          <div className="subcontainer">
            <div className="titleWrapper">
              <p className="title, titleStyle">Get Route</p>
              <button
                onClick={() => modal.current?.dismiss()}
                className="closeButton"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                ×
              </button>
            </div>
            <input
              type="text"
              placeholder="Source Coordinates"
              defaultValue={
                lastClickedCoordinateReversed
                  ? lastClickedCoordinateReversed
                  : ""
              }
              onChange={(e) => {
                setSource(e.target.value);
              }}
              className="input"
              disabled={layerLoading}
            />
            <input
              type="text"
              placeholder="Destination Coordinates"
              defaultValue={
                secondLastClickedCoordinateReversed
                  ? secondLastClickedCoordinateReversed
                  : ""
              }
              onChange={(e) => {
                setDestination(e.target.value);
              }}
              className="input"
              disabled={layerLoading}
            />
            <IonItem style={vesselTypeItemStyle}>
              <IonLabel>Select Vessel Type</IonLabel>
              <IonSelect
                value={vesselType}
                placeholder="Select Vessel Type"
                onIonChange={(e) => setVesselType(e.detail.value)}
                disabled={layerLoading}
                style={selectStyle1}
              >
                <IonSelectOption value="small">Small</IonSelectOption>
                <IonSelectOption value="medium">Medium</IonSelectOption>
                <IonSelectOption value="large">Large</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel>Select Neighbours</IonLabel>
              <IonSelect
                value={neighbors}
                placeholder="Select Neighbours"
                onIonChange={(e) => setNeighbors(e.detail.value)}
                disabled={layerLoading}
                style={selectStyle2}
              >
                <IonSelectOption value={4}>4</IonSelectOption>
                <IonSelectOption value={8}>8</IonSelectOption>
              </IonSelect>
            </IonItem>

            <IonItem>
              <IonLabel>Select Degree of Resolution:</IonLabel>
              <IonSelect
                value={resolutionDegree}
                placeholder="Select Resolution Degree"
                onIonChange={(e) => setResolutionDegree(e.detail.value)}
                disabled={layerLoading}
                style={selectStyle3}
              >
                {Array.from({ length: 10 }, (_, i) => (i + 1) * 0.1).map(
                  (value) => (
                    <IonSelectOption
                      key={value}
                      value={parseFloat(value.toFixed(1))}
                    >
                      {value.toFixed(1)}
                    </IonSelectOption>
                  )
                )}
              </IonSelect>
            </IonItem>

            <div className="router__buttons__wrapper">
              <button
                onClick={handlePathGenerator}
                className="button"
                disabled={layerLoading}
              >
                Get Route
              </button>
              <button
                onClick={handleRemoveCurrentRoute}
                className="button"
                disabled={layerLoading}
              >
                Remove Current Route
              </button>
            </div>
          </div>
          <div className="divider" />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SettingsSheet;
