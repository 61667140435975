/*

This file defines a modal component (LayerModal) in Ionic React that allows users to select or deselect different map layers 
(like Wind Speed and Bathymetry) using checkboxes and updates the visibility of these layers in the map context.

*/

import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonModal,
} from "@ionic/react";
import { useContext, useState, useEffect } from "react";
import { MapContext } from "../../store/MapContext";
import "./LayerModal.css";

const baseIP = process.env.REACT_APP_BASE_IP || "http://localhost:5001/";

const LayerModal = () => {
  const {
    setShowWind,
    setShowBathymetry,
    layerModalIsOpen,
    setLayerModalIsOpen,
    setTimestamp,
  } = useContext(MapContext);
  const [timestampModalOpen, setTimestampModalOpen] = useState(false);
  const [timestamps, setTimestamps] = useState<string[]>([]);

  // Fetch timestamps for wind data when the timestamp modal is opened
  useEffect(() => {
    const fetchTimestamps = async () => {
      const response = await fetch(`${baseIP}/fetch-timestamps`);
      const data = await response.json();
      setTimestamps(data); // Assuming the API returns an array of timestamps
    };

    if (timestampModalOpen) {
      fetchTimestamps();
    }
  }, [timestampModalOpen]);

  const handleWindButtonClick = () => {
    setTimestampModalOpen(true); // Open the timestamp selection modal
    setLayerModalIsOpen(false); // Close the layer modal
  };

  const handleBathymetryButtonClick = () => {
    setShowBathymetry(true); // Show the bathymetry layer
    setLayerModalIsOpen(false); // Close the layer modal
  };

  const handleTimestampSelection = (timestamp: string) => {
    if (timestamp === "Closest Timestamp") {
      setTimestamp(""); // Clear the timestamp when "Closest Timestamp" is selected
    } else {
      setTimestamp(timestamp); // Set the selected timestamp
    }
    setShowWind(true); // Show the wind layer for the selected timestamp
    setTimestampModalOpen(false);
  };

  const handleBackToLayerModal = () => {
    setTimestampModalOpen(false);
    setLayerModalIsOpen(true); // Open the main layer modal again
  };

  const handleRemoveLayers = () => {
    setShowWind(false); // Remove wind layer
    setShowBathymetry(false); // Remove bathymetry layer
  };

  return (
    <>
      <IonModal
        isOpen={layerModalIsOpen}
        class="layer-modal"
        id="layer-modal"
        backdropDismiss
        onDidDismiss={() => setLayerModalIsOpen(false)}
      >
        <IonContent class="layer-modal-content">
          <div className="layer-modal-header">
            <h1 className="layer-modal-title">Select Layer</h1>
          </div>
          <IonList class="layer-modal-list-container">
            <IonItem class="layer-modal-button">
              <IonButton onClick={handleWindButtonClick}>Wind Layer</IonButton>
            </IonItem>
            <IonItem class="layer-modal-button">
              <IonButton onClick={handleBathymetryButtonClick}>
                Bathymetry Layer
              </IonButton>
            </IonItem>
            <IonItem class="layer-modal-button">
              <IonButton onClick={handleRemoveLayers} color="danger">
                Remove Displayed Layers
              </IonButton>
            </IonItem>
          </IonList>
        </IonContent>
      </IonModal>

      {/* Modal to show timestamps for the wind layer */}
      <IonModal
        isOpen={timestampModalOpen}
        class="timestamp-modal"
        onDidDismiss={() => setTimestampModalOpen(false)}
      >
        <IonContent>
          <div className="timestamp-modal-header">
            <h1>Select a Timestamp</h1>
          </div>
          <IonList>
            {/* Add the default option at the top of the list */}
            <IonItem
              button
              onClick={() => handleTimestampSelection("Closest Timestamp")}
            >
              Closest Timestamp
            </IonItem>
            {timestamps.map((timestamp) => (
              <IonItem
                key={timestamp}
                button
                onClick={() => handleTimestampSelection(timestamp)}
              >
                {timestamp}
              </IonItem>
            ))}
          </IonList>
          <div className="timestamp-modal-footer">
            <IonButton onClick={handleBackToLayerModal} color="primary">
              Back to Layers
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default LayerModal;
